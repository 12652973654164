import React, { Fragment,useEffect } from "react";
import GifSpinner from "../Assetspatron/spinnervideo.mp4";
import { useTranslation } from "react-i18next";
import {animateScroll as scroll} from "react-scroll"
import Aos  from "aos"
import "aos/dist/aos.css"







export default function Spinner() {
  //Translations
  const [t] = useTranslation("global");

  //Scroll

  const toggleHome = () => {
    scroll.scrollToTop();
  };

   //Aos
   useEffect(() => {
    Aos.init({ duration:1300 });
},[]);
  return (
    <Fragment>
      <section data-aos="fade-up" className="SpinnerSection">
        <div className="Spinner-info-section">
          <h3 className="H3-info-title">{t("Spinner.Title")}</h3>
          <p className="P-info-text">{t("Spinner.Text")} </p>
          <button onClick={toggleHome} className="Btn-spinner">
            {t("Spinner.Btn")}{" "}
          </button>
        </div>
        <div className="Spinner-container">
          <video
            className="Spinner-video"
            autoplay="true"
            loop="true"
            muted="true"
            alt="spinner"
            src={GifSpinner}
          ></video>
        </div>
      </section>
    </Fragment>
  );
}
