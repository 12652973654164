import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter}  from "react-router-dom"
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import StoreProvider from "./components/Store"

//Translations

import global_es from "./Translations/En/global.json"
import global_en from "./Translations/Es/global.json"

i18next.init({
  interpolation: { escapeValue: false},
  lng:"en",
  resources:{
    es:{
      global: global_es
    },
    en:{
      global: global_en
    }
  }
});


ReactDOM.render(
    <BrowserRouter>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
    </BrowserRouter>,
  document.getElementById('root')
);
