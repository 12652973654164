import React from "react";
import { Fragment,useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import Frame from "../Assetspatron/handcontactus.svg";
import Aos  from "aos"
import "aos/dist/aos.css"
import useScript from "../hooks/useScript";



export default function Adviser() {

  //Tidio



   const [showTidio, setTidio] = useState(false);
  
    useScript("//code.tidio.co/qmnxgfvr2i9i2smimhvm1whqtduaqyvd.js")

  
  //Translations
  const [t] = useTranslation("global");


      //Aos
      useEffect(() => {
        Aos.init({ duration:1300 });
    },[]);

  return (
    <Fragment>

      {/* Tidio Chat */}

      <section id="Adviser" className="Adviser-section-container">
        <article className="Advisercontent-article-container">
          <article className="Info-adviser-container">
            <h3 className="Adviser-title"> {t("Adviser.title")}</h3>
            <p className="Adviser-subtitle">
            {t("Adviser.call")}
            </p>
            <p className="Adviser-description">
            {t("Adviser.description")}

            </p>
          </article>

          <div data-aos="fade-up" className="Seconsection-container-phone">
            <article className="Adviser-image-container">
              <div className="Btn-containers">
                <a target="blank" href="https://api.whatsapp.com/send?phone=34685681375&text=El%20equipo%20de%20PatronBit%20esta%20listo%20para%20ayudarte">
                <button className="WhatsApp-btn">WHATSAPP</button>

              </a>
              <a target="blank" href="https://msng.link/o/?PatronBitOK=tg">
                <button className="WhatsApp-btn Telegrambtn">TELEGRAM</button>

              </a></div>
<a  target="blank" href="https://www.tidio.com/talk/qmnxgfvr2i9i2smimhvm1whqtduaqyvd"> <button  className="Tidio-adviser">Chat en vivo</button></a>

              
              <img alt="Phone with messages, contact us" src={Frame}></img>
            </article>
          </div>
        </article>
      </section>
    </Fragment>
  );
}
