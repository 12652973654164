import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import blacklogo from "../Assetspatron/logoblack.svg";
import { NavLink } from "react-router-dom";
import menu from "../Assetspatron/menu.svg";
import arrow from "../Assetspatron/down-arrow.svg";
import { animateScroll as scroll, Link } from "react-scroll";

const NavBar = (props) => {

  //Translations
  const [t, i18n] = useTranslation("global");

  //DropDone

  const [open, SetOpen] = useState(false);

  //NavBar

  const [showLinks, setShowLinks] = useState(false);

  //Scroll Down Effect

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  
  //Translations functions

  function english() {
    i18n.changeLanguage("en");
    setShowLinks(!showLinks)
    SetOpen(false);
  }

  function spanish() {
    i18n.changeLanguage("es");
    SetOpen(false);
    setShowLinks(!showLinks)

  }

  return (
    <Fragment>
      <div className="Container-navbar">
        <nav className="Navbar">
          <Link
            onClick={toggleHome}
            className="Logo-container"
            to="/"
            duration={500}
            
          >
            <img
              style={{cursor:"pointer"}}
              src={blacklogo}
              alt="Logo Patron Bit, is a dog with a bitcoint neckless"
            ></img>
          </Link>

          <div
            className="Responsive-button-navbar"
            onClick={() => setShowLinks(!showLinks)}
          >
            <img src={menu}></img>
          </div>

          <div className="NavLinks-container" id={showLinks ? "hidden" : ""}>
            <NavLink
              to="/"
              onClick={toggleHome}
              style={{ cursor: "pointer" }}
              className="Link"
              duration={500}
            >
              {t("Header.Home")}
            </NavLink>
            <NavLink
              to="/"
              className="Link"
              style={{ cursor: "pointer" }}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"
            >
              {t("Header.How")}
            </NavLink>
            <NavLink 
            className="Link"
            style={{ cursor: "pointer" }}
              to="/"
              className="Link"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"
            >
              {t("Header.Prices")}
            </NavLink>

            <NavLink
              style={{ cursor: "pointer" }}
              to="/"
              className="Link"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"
            >
              {t("Header.About")}
            </NavLink>
            <NavLink
              style={{ cursor: "pointer" }}
              to="/"
              className="Link"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"
            >
              {t("Header.Contact")}
            </NavLink>

            <div
              style={{
                cursor: "pointer",
                marginLeft: "17px",
                marginTop: "3px",
              }}
              onClick={() => SetOpen(!open)}
            >
              {t("Header.language")}{" "}
              <img style={{ width: "11px" }} src={arrow}></img>
              {open ? (
                <div className="LenguageButton-container">
                  <div
                    className="Language-btn "
                    style={{ cursor: "pointer" }}
                    onClick={() => spanish()}
                  >
                    English
                  </div>
                  <div
                    className="Language-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => english()}
                  >
                    Español
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default withRouter(NavBar);
