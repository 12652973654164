import {Fragment} from "react";
import NavBar from "../components/NavBar";
import Client from "../components/Client";
import Footer from "../components/Footer";
import useScript from "../hooks/useScript";

function TransactionPage(props) {
    useScript("//code.tidio.co/qmnxgfvr2i9i2smimhvm1whqtduaqyvd.js");
    return (
        <Fragment>
            <NavBar />
            <Client calculatorData={props.calculatorData}/>
            <Footer />
        </Fragment> 
    )
}

export default TransactionPage;