
import React, { Fragment, useState, useEffect } from "react";
import MaterialTable from "material-table";
import blacklogo from "./Assetspatron/logoblack.svg";
import Modal from "react-modal";
import { Ring } from "react-css-spinners";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useAlert } from 'react-alert';
import { CsvBuilder } from 'filefy';
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx"
import { parseJSON } from "date-fns";
import { getTransactions, logout, getPercents, getUsers, createTransaction, editTransaction, editPercent, deleteTransaction, editUser, deleteUser, checkIfStatus400, checkIfStatus401, checkIfStatus403 } from "./api/requests";
import NewUserForm from "./components/NewUserForm";

const PatronUsers = [
  {
    title: "Usuario", field: "username",
  },
  { title: "Nombre", field: "name", },
  { title: "Contraseña", field: "password", render: (rowData) => "********" },
  { title: "Rol", field: "role", lookup: { admin: "Admin", super: "Super" } }

]

function AppNewAdmin() {
  const history = useHistory();
  const [data, setData] = useState({
    transactions: [],
    percents: [],
    users: [],
    isLoading: true,
    isPercentsLoading: true,
    isUsersLoading: true
  });
  const [selectedTransactions, setSelectedTransactions] = useState([]); 
  const [modalIsOpen, setModalIsOPen] = useState(false);
  const [modalTwoOpen, setModalTwoOpen] = useState(false);
  const [modalThreeOpen, setModalThreeOpen] = useState(false);
  const alert = useAlert();
  
  const columns = [

    {
      title: "Fecha",
      field: "transactionDate",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) => {
        let date = new Date(rowData.transactionDate);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      },
    },
    {
      title: "Estado Transaccion",
      field: "transactionCompleted",
      lookup: { nuevo:"nuevo", pendiente:"pendiente", enviada:"enviada", recibida:"recibida", reclamo:"reclamo", anulado:"anulado", terminado:"terminado"},
    
    },
    {
      title: "Tipo Operación",
      field: "operationType",
      lookup: { c: "compra", v: "venta" },
      validate: (rowData) =>
        rowData.operationType === "" ? { isValid: false, helperText: "Obligatorio" } : true,
    },
    {
      title: "Monto Fiat",
      field: "amount",
      type: "numeric",
      render: (rowData) => (rowData.fiatType === "EUR" ? `${rowData.amount} €` : rowData.fiatType === "USD" ? `${rowData.amount} $` : rowData.amount),
      validate: (rowData) => (!rowData.amount === "" ? { isValid: false, helperText: "Obligatorio" } : Number(rowData.amount) <= 0 ? { isValid: false, helperText: "Debe ser mayor a 0" } : true),
    },
    {
      title: "Moneda",
      field: "fiatType",
      type: "string",
      lookup: { USD: "USD", EUR: "EUR" },
      validate: (rowData) => (rowData.fiatType === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
    {
      title: "Cantidad Cripto",
      field: "cryptoQTY",
      type: "numeric",
      render: (rowData) => `${rowData.cryptoQTY} ${rowData.cryptoType}`,
      validate: (rowData) => (!rowData.cryptoQTY === "" ? { isValid: false, helperText: "Obligatorio" } : Number(rowData.cryptoQTY) <= 0 ? { isValid: false, helperText: "Debe ser mayor a 0" } : true),
    },
    {
      title: "Tipo de Cripto",
      field: "cryptoType",
      lookup: {
        BTC:"BTC", USDT:"USDT", ETH:"ETH", XRP:"XRP", ADA:"ADA", DOT:"DOT", DAI:"DAI", LTC:"LTC", BCH:"BCH", DOGE:"DOGE", BUSD:"BUSD", USDC:"USDC"},
      validate: (rowData) => (rowData.cryptoType === "" ? { isValid: false, helperText: "Obligatorio" } : true)
    },
    {
      title: "Método Pago",
      field: "paymentType",
      lookup: {
        transferencia: "transferencia",
        tarjeta: "tarjeta",
        efectivo: "efectivo",
        validate: (rowData) =>
          rowData.paymentType === "" ? { isValid: false, helperText: "Obligatorio" } : true,
      },
    },
    {
      title: "Cuenta Emisor",
      field: "bancoE",
      validate: (rowData) => (rowData.bancoE === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
    {
      title: "Cuenta Receptor",
      field: "bancoR",
      validate: (rowData) => (rowData.bancoR === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },

    {
      title: "TXID",
      field: "transactiontx",
      validate: (rowData) => (rowData.transactiontx === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
    { title: "Ticket", field: "ticket", editable: "never"},
    {
      title: "Nombre",
      field: "name",
      validate: (rowData) => (rowData.name === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
    {
      title: "Correo Electrónico",
      field: "email",
      validate: (rowData) => (rowData.email === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
    {
      title: "Num Teléfono",
      field: "phone",
      validate: (rowData) => (rowData.phone === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },

    {
      title: "Num Documento",
      field: "ndocumento",
      validate: (rowData) => (rowData.ndocumento === "" ? { isValid: false, helperText: "Obligatorio" } : true),
    },
  
    
    {
      title: "Agregar   Notas",
      field: "notes",
      render: (rowData) => {
        if(rowData.notes) return rowData.notes
        else return "No hay nota"
      },
    },
  ];

//COLUMNS PERCENTS

  const columnspercents = [
    {
      title: "Transferencia",
      field: "transfer",
      type: "numeric",
      render: (rowData) => `${rowData.transfer}%`,
    },
    {
      title: "Tarjeta",
      field: "card",
      type: "numeric",
      render: (rowData) => `${rowData.card}%`,
    },
    {
      title: "Efectivo",
      field: "cash",
      type: "numeric",
      render: (rowData) => `${rowData.cash}%`,
    },
  ];

  useEffect(() => {
    if (data.transactions.length === 0) getTransactionData();
    if (modalIsOpen && data.percents.length === 0) getPercentsData();
    if (modalThreeOpen && data.users.length === 0) getUsersData();
  }, [data, modalIsOpen, modalThreeOpen]);

  useEffect(() => {
    if (!modalThreeOpen) setData(data => ({ ...data, users: [], isUsersLoading: true }))
    if (!modalIsOpen) setData(data => ({ ...data, percents: [], isPercentsLoading: true }))
  }, [modalIsOpen, modalThreeOpen])

  const getTransactionData = async () => {
    const res = await getTransactions();
    if(res.success) {
      setData({ ...data, transactions: [...res.payload], isLoading: false });
    } else {
      if(res.payload) {
        checkIfStatus401(res, history);
        checkIfStatus403(res, alert)
      } else {
        console.log("error de red");
      }
    }
  };

  const getPercentsData = async () => {
    const res = await getPercents();
    if(res.success) {
      setData({
        ...data,
        percents: [{ ...res.payload }],
        isPercentsLoading: false,
      });
    } else {
      if(res.payload) {
        checkIfStatus401(res, history);
        checkIfStatus403(res, alert);
      } else {
        console.log("error de red");
      }
    }
  };

  const getUsersData = async () => {
    const res = await getUsers();
    if(res.success) {
      let newData = res.payload.users.map(v => ({ ...v, password: "" }))
      setData({
        ...data,
        users: [...newData],
        isUsersLoading: false,
      });
    } else {
      if(res.payload) {
        checkIfStatus401(res, history);
        checkIfStatus403(res, alert);
      } else {
        console.log("error de red");
      }
    }
  }

  const createTransactionData = async (data) => {
    const res = await createTransaction(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getTransactionData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  };

  const editTransactionData = async (data) => {
    const res = await editTransaction(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getTransactionData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  };

  const editPercentData = async (data) => {
    const res = await editPercent(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getPercentsData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  };

  const deleteTransactionData = async (data) => {
    const res = await deleteTransaction(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getTransactionData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  };

  const editUserData = async (data) => {
    const res = await editUser(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getUsersData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  }

  const deleteUserData = async (data) => {
    const res = await deleteUser(data);

    if (res.success) {
      if(res.payload.success) {
        alert.show(res.payload.msg, { type: 'success' })
        getUsersData();
      } else {
        alert.show(res.payload.msg, { type: 'error' })
      }
    } else {
      checkIfStatus400(res, alert);
      checkIfStatus401(res, history);
      checkIfStatus403(res, alert);
    }
  }

  const exportSelectedTransactions = () => {
    let t = selectedTransactions.map(data => Object.assign({...data}, {
      operationType: data.operationType === "c" ? "compra" : 
      data.operationType === "v" ? "venta" : data.operationType}))

    new CsvBuilder("PatronBit.csv")
      .setColumns(columns.map(col => col.title))
      .addRows(t.map(tData => columns.map(col => tData[col.field])))
      .exportFile();
  }

  const Logout = async () => {
    await logout(history);
  };

  return (
    <div className="App">
      {/* First Modal User Register*/}
      {console.log(selectedTransactions)}
      <Modal isOpen={modalTwoOpen} className="Register-Modal">
        <NewUserForm getUsersData={getUsersData} setModalTwoOpen={setModalTwoOpen}/>
      </Modal>

      {/* ADMIN CREDENTIALS*/}
      <Modal isOpen={modalThreeOpen}>
        <button className="Close-register Close-credentials" onClick={() => setModalThreeOpen(false)}>X</button>
        {data.isUsersLoading ? (
          <section className={"Spinner-ring"}>
            <Ring size={120} color={"#a82321"} />
          </section>
        ) : (

          <MaterialTable
            title={"Administrar Usuarios"}
            data={data.users}
            columns={PatronUsers}
            editable={
              {
                onRowDelete: (selectedRow) =>
                  new Promise((resolve, reject) => {
                    let res = deleteUserData({ id: selectedRow._id });
                    resolve();
                  }),
                onRowUpdate: (updatedRow, oldRow) =>
                  new Promise(async (resolve, reject) => {
                    let updatedData = {};
                    Object.keys(oldRow).forEach(function (key) {
                      if (updatedRow[key]) {
                        if (oldRow[key] !== updatedRow[key]) {
                          updatedData[key] = updatedRow[key];
                        }
                      }
                    });
                    updatedData.id = updatedRow._id;
                    let res = editUserData(updatedData);
                    resolve();
                  }),

              }}

            options={{
              actionsColumnIndex: -1

            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () => {
                  setData({ ...data, isUsersLoading: true });
                  getUsersData();
                },
              },
            ]}
          >

          </MaterialTable>
        )}

      </Modal>

      <div className="Section-logo-admin">
        <img className="admin-logo" src={blacklogo} alt="Logo"></img>
        <div className="Btn-container-admin">
          <button
            className="Admin-btn adminpercenbtn"
            onClick={() => setModalIsOPen(true)}
          >
            Porcentajes
          </button>
          <button
            className="Admin-btn adminpercenbtn"
            onClick={() => setModalTwoOpen(true)}
          >
            Crear Usuario
          </button>

          <button
            className="Admin-btn adminpercenbtn"
            onClick={() => setModalThreeOpen(true)}
          >
            Usuarios
          </button>

          <button className="Admin-btn" onClick={Logout}>
            Cerrar Sesión
          </button>
        </div>
      </div>
      <h2 align="center" className="Title-admin">
        Panel Administrativo
      </h2>
      {data.isLoading ? (
        <section className={"Spinner-ring"}>
          <Ring size={120} color={"#a82321"} />
        </section>
      ) : (
        <MaterialTable
          style={{ border: "5px solid #a82321", zIndex: "0"}}
          
          title="PatronBit"
          data={data.transactions}
          columns={columns}
          editable={{
            onRowAdd: (newRow) =>
              new Promise((resolve, reject) => {
                //console.log(newRow);
                let res = createTransactionData(newRow);
                resolve();
              }),
            onRowDelete: (selectedRow) =>
              new Promise((resolve, reject) => {
                let res = deleteTransactionData({ id: selectedRow.id });
                resolve();
              }),
            onRowUpdate: (updatedRow, oldRow) =>
              new Promise(async (resolve, reject) => {
                let updatedData = {};
                Object.keys(oldRow).forEach(function (key) {
                  if (updatedRow[key]) {
                    if (oldRow[key] !== updatedRow[key]) {
                      updatedData[key] = updatedRow[key];
                    }
                  }
                });

                updatedData.id = updatedRow.id;
                console.log(updatedData)
                let res = editTransactionData(updatedData);
                resolve();
              }),
          }}
          options={{
            selection: true,
            actionsColumnIndex: -1,
            addRowPosition: "first",
            exportButton:true,
            headerStyle:{
              backgroundColor:"#a82321",
              color:"white"
            }

          }}
          actions={[
            {
              tooltip: 'Exportar transacciones',
              icon: 'download',
              onClick: (evt, data) => exportSelectedTransactions()//console.log('You want to delete ' + data.length + ' rows')
            },
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () => {
                setData({ ...data, isLoading: true });
                getTransactionData();
              },
            },
          ]}
          onSelectionChange={(rows) => setSelectedTransactions(rows)}
        />
      )}

      {/* Percents Section Second Modal */}
      <Modal className="Modal" isOpen={modalIsOpen}>
        {data.isPercentsLoading ? (
          <section
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ring size={120} color={"#a82321"} />
          </section>
        ) : (
          <Fragment>
            <section className="Modal-container Percent-Container">
              <button
                className="closemodalbtn Percent-btn-modalclose"
                onClick={() => setModalIsOPen(false)}
              >
                x
              </button>
              <MaterialTable
                style={{ width: "600px", marginTop: "40px" }}
                title="Porcentajes"
                data={data.percents}
                columns={columnspercents}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                }}
                editable={{
                  onRowUpdate: (updatedRow, oldRow) =>
                    new Promise((resolve, reject) => {
                      let updatedData = {};
                      Object.keys(oldRow).forEach(function (key) {
                        if (updatedRow[key]) {
                          if (oldRow[key] !== updatedRow[key]) {
                            updatedData[key] = updatedRow[key];
                          }
                        }
                      });
                      let res = editPercentData(updatedData);
                      resolve();
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                }}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => {
                      setData({ ...data, isPercentsLoading: true });
                      getPercentsData();
                    },
                  },
                ]}
              ></MaterialTable>

            </section>
          </Fragment>
        )}
      </Modal>

    </div>
  );
}

export default AppNewAdmin;
