import React, { Fragment, useEffect, useState } from "react";

//Functional Dependencies

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { getPrice } from "../api/requests";
//Images Icons
import btc from "../Assetspatron/BTC.svg";
import dot from "../Assetspatron/dot.svg";
import dodge from "../Assetspatron/dodge.svg";
import ada from "../Assetspatron/ada.svg";
import etc from "../Assetspatron/etc.svg";
import dai from "../Assetspatron/dai.svg";
import ltc from "../Assetspatron/lite.svg";
import usdc from "../Assetspatron/usdc.svg";
import busd from "../Assetspatron/busd.svg";
import xrp from "../Assetspatron/xrp.svg";
import usdt from "../Assetspatron/usdt.svg";
import bch from "../Assetspatron/bch.svg";
import credit from "../Assetspatron/credit-card.svg";
import arrows from "../Assetspatron/atransfer.svg";
import cash from "../Assetspatron/cash.svg";

Modal.setAppElement("#root");

export default function Calculator(props) {
  const [usd, setUsd] = useState(false);

  const { REACT_APP_BACKEND_URL } = process.env;
  const history = useHistory();
  const alert = useAlert();

  //Modal
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  //Translations
  const [t] = useTranslation("global");

  const {
    cryptos = [
      { img: usdt, type: "USDT" },
      { img: btc, type: "BTC" },
      { img: etc, type: "ETH" },
      { img: xrp, type: "XRP" },
      { img: ada, type: "ADA" },
      { img: dot, type: "DOT" },
      { img: dai, type: "DAI" },
      { img: ltc, type: "LTC" },
      { img: bch, type: "BCH" },
      { img: dodge, type: "DOGE" },
      { img: busd, type: "BUSD" },
      { img: usdc, type: "USDC" },
    ],
  } = props;


  const [inputFiat, setInputFiat] = useState({
    type: "EUR",
    amount: "",
    isFocused: false,
  });

  const [inputCrypto, setInputCrypto] = useState({
    type: "USDT",
    amount: "",
    isFocused: false,
  });
  const [operationType, setOperationType] = useState({
    buySell: "c",
    paymentOption: "transferencia",
  });
  const [state, setState] = useState({
    typing: true,
    typingTimeout: 0,
    calculatorBtn: true,
    formBtn: true,
    disabledInputs: false,
  });
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });
  const [amountMsg, setAmountMsg] = useState("");

  useEffect(() => {
    if (
      inputFiat.type !== "" &&
      inputFiat.amount >= 50 &&
      inputCrypto.type !== "" &&
      operationType.paymentOption !== ""
    ) {
      setState({ ...state, calculatorBtn: false });
    }
    if (operationType.paymentOption !== "") {
      setState({ ...state, disabledInputs: false, calculatorBtn: true });
      setAmountMsg("");
      setInputCrypto({
        ...inputCrypto,
        amount: "",
      });
      setInputFiat({
        ...inputFiat,
        amount: "",
      });
    } else {
      setState({ ...state, disabledInputs: true });
    }
  }, [operationType.paymentOption]);

  //UseEffect Fiat
  useEffect(() => {
    if (inputFiat.isFocused) {
      if (inputFiat.amount !== "" && Number(inputFiat.amount) >= 50) {
        setAmountMsg("");
        if (!state.typing) {
          const params = {
            amount: inputFiat.amount,
            to: inputCrypto.type,
            from: inputFiat.type,
            paymentMethod: operationType.paymentOption,
            operationType: operationType.buySell,
          };

          async function price() {
            const response = await getPrice(params);
            if (response.success) {
              setInputCrypto({
                ...inputCrypto,
                amount: Math.ceil(response.data.price * 100000000) / 100000000,
              });
              operationType.paymentOption !== ""
                ? setState({ ...state, calculatorBtn: false })
                : setState({ ...state, calculatorBtn: true });
            }
          }
          price();
        }
      } else {
        if (inputFiat.amount !== "") {
          if (inputFiat.type === "EUR") {
            setAmountMsg(t("Converter.Alert"));
          } else {
            setAmountMsg(t("Converter.Alertusd"));
          }
        } else {
          setAmountMsg("");
        }

        setInputCrypto({
          ...inputCrypto,
          amount: "",
        });
        setState({ ...state, calculatorBtn: true });
      }
    }
  }, [inputFiat, state.typing]);

  useEffect(() => {
    if (inputCrypto.isFocused) {
      if (inputCrypto.amount !== "" && Number(inputCrypto.amount) > 0) {
        if (!state.typing) {
          async function price() {
            const params = {
              amount: inputCrypto.amount,
              to: inputFiat.type,
              from: inputCrypto.type,
              paymentMethod: operationType.paymentOption,
              operationType: operationType.buySell,
            };
            const response = await getPrice(params);
            if (response.success) {
              if (response.data.price < 50) {
                alert.show("El monto debe ser mayor o igual a 50€");
              } else {
                setAmountMsg("");
              }
              setInputFiat((inputFiat) => ({
                ...inputFiat,
                amount: Math.ceil(response.data.price),
              }));
              operationType.paymentOption !== ""
                ? setState({ ...state, calculatorBtn: false })
                : setState({ ...state, calculatorBtn: true });
            }
          }

          price();
        }
      } else {
        setInputFiat({
          ...inputFiat,
          amount: "",
        });
        setState({ ...state, calculatorBtn: true });
      }
    }
  }, [inputCrypto, state.typing]);

  useEffect(() => {
    if (operationType.buySell !== "" || inputFiat.type !== "") {
      setState({ ...state, calculatorBtn: true });
      setAmountMsg("");
      setInputCrypto({
        ...inputCrypto,
        amount: "",
      });
      setInputFiat({
        ...inputFiat,
        amount: "",
      });
    }
  }, [operationType.buySell, inputFiat.type]);

  const handleButton = (e) => {
    setInputCrypto({ ...inputCrypto, amount: "", type: e.target.name });
    setInputFiat({ ...inputFiat, amount: "" });
  };

  function handleFocus(e) {
    if (e.target.id === "fiat") setInputFiat({ ...inputFiat, isFocused: true });
    else if (e.target.id === "crypto")
      setInputCrypto({ ...inputCrypto, isFocused: true });
  }

  function handleBlur(e) {
    if (e.target.id === "fiat")
      setInputFiat({ ...inputFiat, isFocused: false });
    else if (e.target.id === "crypto")
      setInputCrypto({ ...inputCrypto, isFocused: false });
  }

  function handleBuySellButton(e) {
    e.preventDefault();
    if (e.target.id === "compra")
      setOperationType({ ...operationType, buySell: "c" });
    else if (e.target.id === "venta")
      setOperationType({ ...operationType, buySell: "v" });
  }

  function handleInput(e) {
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }

    let amount = e.target.value.replace(/[/-]/g, "");

    if (e.target.id === "fiat") setInputFiat({ ...inputFiat, amount: amount });
    else if (e.target.id === "crypto")
      setInputCrypto({ ...inputCrypto, amount: amount });

    setState({
      ...state,
      calculatorBtn: true,
      typing: true,
      typingTimeout: setTimeout(function () {
        setState({ ...state, typing: false });
      }, 300),
    });
  }

  function handleFormChange(e) {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (inputFiat.amount < 50) {
      alert.show("El monto debe ser mayor o igual a 50€");
      setInputFiat({ ...inputFiat, amount: "" });
      setInputCrypto({ ...inputCrypto, amount: "" });
      setState({ ...state, calculatorBtn: true });
    } else {
      props.setCalculatorData({
        operationType: operationType.buySell,
        amount: inputFiat.amount,
        fiatType: inputFiat.type,
        cryptoQTY: inputCrypto.amount,
        cryptoType: inputCrypto.type,
        paymentType: operationType.paymentOption,
        isSubmitted: true,
      });
      history.push("/transaction");
    }
  }

  function handleClose() {
    setmodalIsOpen(false);
    setFormData({ name: "", phone: "", email: "" });
  }

  function handleClick(e) {
    e.preventDefault();
    setOperationType({
      ...operationType,
      paymentOption: e.target.name,
    });
  }

  return (
    <Fragment>
      {/*console.log(process.env)*/}
      <div className="Form-card">
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <section
          onClick={() => setInputFiat({ ...inputFiat, type: "EUR" })}
            className={clsx(inputFiat.type === "EUR" && "Buysellbtn-selectede")}
            style={{
              cursor: "pointer",
              width: "50%",
              textAlign: "center",
              padding: "20px 0",
              backgroundColor: "white",
              borderRadius: "13px 0px 13px 0px",
            }}
          >
            <div onClick={() => setInputFiat({ ...inputFiat, type: "EUR" })}>
              {t("Converter.Eur")}
            </div>
          </section>

          <section
          onClick={() => setInputFiat({ ...inputFiat, type: "USD" })}
            className={clsx(inputFiat.type === "USD" && "Buysellbtn-selectede")}
            style={{
              cursor: "pointer",
              width: "50%",
              textAlign: "center",
              padding: "20px 0",
              backgroundColor: "white",
              borderRadius: "0px 13px 0px 13px",
            }}
          >
            <div onClick={() => setInputFiat({ ...inputFiat, type: "USD" })}>
              {t("Converter.Usd")}
            </div>
          </section>
        </div>
        <form className="Form-calculator">
          {/* ... Forms Start ... */}
          <div className="Buysellbtn-calculator-container">
            <button
              id="compra"
              className={clsx(
                "Buysellbtn",
                operationType.buySell === "c" && "Buysellbtn-selected"
              )}
              type="button"
              onClick={handleBuySellButton}
            >
              {t("Converter.Buy")}
            </button>

            <button
              id="venta"
              className={clsx(
                "Buysellbtn",
                operationType.buySell === "v" && "Buysellbtn-selected"
              )}
              type="button"
              onClick={handleBuySellButton}
            >
              {t("Converter.Sell")}
            </button>
          </div>

          {/* ... Forms Crypto wwButtons Start ... */}
          <div className="Buttons-container">
            {/*CryptoShorcuts summary
            BTC = BitCoin, BCH = Bitcoincash, ETH = Ethereum, USDT = Tether
             DOGE = dogecoin, ADA = Cardano, dot = polkadot, xrp = ripple */}

            {cryptos.map((crypto) => {
              return (
                <button
                  id={crypto.type}
                  type="button"
                  className={clsx(
                    "Calculator-btn",
                    crypto.type === inputCrypto.type &&
                      `${crypto.type}-selected`
                  )}
                  name={crypto.type}
                  onClick={handleButton}
                >
                  <img
                    className="btn-logo"
                    name={crypto.type}
                    src={crypto.img}
                    alt={crypto.type}
                  ></img>
                  {crypto.type}
                </button>
              );
            })}
          </div>

          {/* Coin Buttons ends */}

          <div className="Payment-methods-container">
          <button
              name="efectivo"
              className={clsx(
                "Payment-btn",
                operationType.paymentOption == "efectivo" &&
                  "Payment-btn-selected"
              )}
              onClick={handleClick}
            >
              <span className="payimg-cont" name="efectivo">
                <img
                  src={cash}
                  name="efectivo"
                  alt="cash button"
                  className="img-payment"
                ></img>
              </span>
              {t("Converter.Cash")}
            </button>
            <button
              name="transferencia"
              className={clsx(
                "Payment-btn",
                operationType.paymentOption == "transferencia" &&
                  "Payment-btn-selected"
              )}
              onClick={handleClick}
            >
              <span className="payimg-cont" name="transferencia">
                <img
                  src={arrows}
                  name="transferencia"
                  alt="transfer button"
                  className="img-payment"
                ></img>
              </span>
              {t("Converter.Transfer")}
            </button>

           
            <button
              name="tarjeta"
              className={clsx(
                "Payment-btn",
                operationType.paymentOption == "tarjeta" &&
                  "Payment-btn-selected"
              )}
              onClick={handleClick}
            >
              <span className="payimg-cont" name="tarjeta">
                <img
                  src={credit}
                  name="tarjeta"
                  alt="card button"
                  className="img-payment"
                ></img>
              </span>
              {t("Converter.Card")}
            </button>
          </div>

          {/* Converter Starrrt*/}
          <span>{amountMsg}</span>
    
            <div className="Converter-inputs-container">
              <div className="Converter-label-container">
                <input
                  id="crypto"
                  disabled={state.disabledInputs}
                  className="Input-calculator input-top"
                  value={inputCrypto.amount}
                  onChange={handleInput}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  type="number"
                ></input>
                <span className="Label-coin">{inputCrypto.type}</span>
              </div>

              <div className="Converter-label-container">
                <input
                  id="fiat"
                  disabled={state.disabledInputs}
                  className="Input-calculator input-bottom"
                  value={inputFiat.amount}
                  onChange={handleInput}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  type="number"
                  min="50"
                ></input>
                {inputFiat.type === "USD" ? (
                  <span className="crypto-label">USD</span>
                ) : (
                  <span className="crypto-label">EUR</span>
                )}
              </div>
            </div>
         
        
        </form>

        <button
          disabled={state.calculatorBtn}
          onClick={handleSubmit}
          className={clsx(
            "Calculator-btnbuy",
            state.calculatorBtn && "Calculator-btnbuy-disabled"
          )}
        >
          {operationType.buySell === "v" && "Buysellbtn-selected"
            ? t("Converter.Sell")
            : t("Converter.Buy")}
        </button>
      </div>
    </Fragment>
  );
}
