import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import FormImg from "../Assetspatron/FormImg.svg"
import blacklogo from "../Assetspatron/logoblack.svg"
import { getData, login } from "../api/requests";

const SignUp = () => {
    const [ loginData, setLoginData ] = useState({user: "", pass: ""}) 
    const history = useHistory();
    const location = useLocation();
    const alert = useAlert();

    useEffect(() => {
        function checkExpired() {
            if(location.state) {
                if(location.state.expired) {
                    alert.show("La sesión ha expirado", { type: 'error' })
                    history.replace({ state: { expired: false } })
                    return true
                }
            }
            return false
        }

        async function checkIfLoggedIn() {
            const res = await getData();
            if(res.success) history.push("/ghp_iPBAa!YkJI0LtZif0r0PeH3K3WcQp3hIxz2mI02b")
        }
        
        const returned = checkExpired();
        if(!returned) checkIfLoggedIn();
    }, [])

    const handleChange = (e) => {
        if(!e.target.value.includes(" ")) {
            setLoginData({...loginData, [e.target.id]: e.target.value})
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        if(loginData.user === "") {
            alert.show("Campo usuario vacio", { type: 'error' });
            return
        }
        if(loginData.pass === "") {
            alert.show("Campo contraseña vacio", { type: 'error' });
            return
        }

        const res = await login(loginData.user, loginData.pass);
        if(res.success) {
            history.push("/ghp_iPBAa!YkJI0LtZif0r0PeH3K3WcQp3hIxz2mI02b")
        } else {
            if(res.payload) alert.show(res.payload, { type: 'error' });
            else alert.show("Ha ocurrido un error de conexión", { type: 'error' });
        }
    }

    return(
        <Fragment>
             <section className="SignForm-Container">
             <img alt="Logo" src={blacklogo} className="Logo-form-login"></img>

                <article className="Sign-form-containercard">
                    
                    <div>
                        <img alt="Sign In" src={FormImg} className="Sign-In-image"></img>
                    </div>
                    <div className="Form-container-right">
                        <form className="Log-in-form">
                            <h3>Ingresar Administrador </h3>
                            <input id="user" className="Input-Sign" value={loginData.user} placeholder="Nombre de usuario" type="text" onChange={handleChange}></input>
                            <input id="pass" className="Input-Sign" value={loginData.pass} placeholder="Contraseña" type="password" onChange={handleChange}></input>
                            <button className="Log-in-btn" onClick={handleClick}> Ingresar </button>
                        </form>
                        
                    </div>
                </article>
             </section>

        </Fragment>
    )
}



export default SignUp