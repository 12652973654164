import React, {Fragment} from 'react'
import NavBar from "../NavBar"
import Footer from "../Footer"
import { useTranslation } from "react-i18next";


function Privacy() {

    const [t] = useTranslation("global");

    return (
        <Fragment>
            <NavBar></NavBar>
             <section className="Legad-adivser-container">
             <h2 className="Legal-title data-title">{t("General-conditions.Privacy-politics-title")} </h2>
<div>
{t("General-conditions.Privacy-text")}



</div>


<div style={{marginTop:"20px"}}> 
<p>{t("General-conditions.Privacy-more-info")}
</p></div>
</section>

            <Footer></Footer>
        </Fragment>
    )
}

export default Privacy
