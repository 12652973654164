import React, { Fragment,useEffect } from "react";
import securitybox from "../Assetspatron/securitybox.svg";
import location from "../Assetspatron/location.svg";
import chat from "../Assetspatron/chat.svg";
import securityloc from "../Assetspatron/securityloc.svg";
import { useTranslation } from "react-i18next";
import Aos  from "aos"
import "aos/dist/aos.css"

export default function OurClients() {
  const [t] = useTranslation("global");
     //Aos
     useEffect(() => {
      Aos.init({ duration:1300 });
  },[]);
  
  return (
    <Fragment>
      <section className="Ourclients-section">
        <h1 className="Ourclients-title">{t("OurClient.title")}</h1>
        <div className="Clients-facts-container">
          <div className="Responsive-client-container">
            <div data-aos="fade-up" className="Client-fact">
              <img src={securitybox} className="Client-imgfact"></img>
              <div className="Info-ourclient">
                <div className="Our-client-textcontainer">
                  <h4 className="H4-ourclient-title">{t("OurClient.titleone")}</h4>
                  <p className="Tagp-ourclient-fact">
                  {t("OurClient.textone")}
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" className="Client-fact">
              <img src={securityloc} className="Client-imgfact"></img>
              <div className="Info-ourclient">
                <div className="Our-client-textcontainer">
                  <h4 className="H4-ourclient-title">{t("OurClient.titletwo")}</h4>
                  <p className="Tagp-ourclient-fact">
                  {t("OurClient.texttwo")}

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Responsive-client-container"> 
          <div data-aos="fade-up" className="Client-fact">
              <img src={chat} className="Client-imgfact"></img>
              <div className="Info-ourclient">
                <div className="Our-client-textcontainer">
                  <h4 className="H4-ourclient-title">{t("OurClient.titlethree")}</h4>
                  <p className="Tagp-ourclient-fact">
                  {t("OurClient.textthree")}

                  </p>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className="Client-fact">
              <img src={location} className="Client-imgfact"></img>
              <div className="Info-ourclient">
                <div className="Our-client-textcontainer">
                  <h4 className="H4-ourclient-title">{t("OurClient.titlefour")}</h4>
                  <p className="Tagp-ourclient-fact">
                  {t("OurClient.textfour")}

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
