import React from "react";
import { Fragment, useEffect, useState } from "react";
import wallet from "../Assetspatron/wallet.svg";
import walletwo from "../Assetspatron/walletwo.svg";
import nousers from "../Assetspatron/Nousers.svg";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css"
import Criptotable from "./Criptotable";
import { animateScroll as scroll} from "react-scroll";


export default function Facts() {

 //Translations


 //Scroll Down Effect

 const toggleHome = () => {
   scroll.scrollToTop();
 };
 
 


  //Translations
  const [t] = useTranslation("global");

  //Aos
  useEffect(() => {
    Aos.init({ duration: 1300 });
  }, []);

  return (
    <Fragment>
      <section id="Facts" className="Facts-section-container">
        <h2 className="Seconsection-title-container Facts-section-title">
          {t("Benefits.Benefits-title")}
        </h2>
       

         <Criptotable> </Criptotable> 
        
        <article className="Facts-container" data-aos="fade-up">
          <div className="fact">
            <div className="Facts-img-container">
              <img src={nousers} alt="Logo"></img>
            </div>
            <h3 className="facts-title">{t("Benefits.Benefit-title-one")}</h3>
            <p className="Facts-text">{t("Benefits.Benefit-text-one")}</p>
          </div>
          <div className="fact">
            <div className="Facts-img-container">
              <img src={wallet} alt="Logo"></img>
            </div>
            <h3 className="facts-title">{t("Benefits.Benefit-title-two")}</h3>
            <p className="Facts-text">{t("Benefits.Benefit-text-two")}</p>
          </div>
          <div className="fact">
            <div className="Facts-img-container">
              <img src={walletwo} alt="Logo"></img>
            </div>
            <h3 className="facts-title">{t("Benefits.Benefit-title-three")}</h3>
            <p className="Facts-text">{t("Benefits.Benefit-text-three")}</p>
          </div>
        </article>
      </section>
    </Fragment>
  );
}
