import React, {Fragment} from 'react'
import NavBar from "../NavBar"
import Footer from "../Footer"
import { useTranslation } from "react-i18next";


function Legal() {

    const [t] = useTranslation("global");

    return (
        <Fragment>
            <NavBar></NavBar>
             <section className="Legad-adivser-container">
             <h2 className="Legal-title data-title">{t("Legal-warnings.DataITitle")} </h2>
<div>
{t("Legal-warnings.Firsttext")}

<li className="Date-politics">{t("Legal-warnings.SocialTitle")}
</li>
<li className="Date-politics">{t("Legal-warnings.SocialText")}
</li>
{t("Legal-warnings.Patron")}
</div>



<h2 className="title-two">{t("General-conditions.Condition-title")}
</h2>
<div className="Conditions"> {t("General-conditions.Contidition-text")} </div>

<h1 className="AccessSecurity">{t("General-conditions.Security-accesibiliy")}</h1>
<p>{t("General-conditions.Security-text")} </p>


<p className="title-two title-domicilio"> 
{t("Legal-warnings.InscripcionText")}
</p>
<p className="title-two"> 
{t("Legal-warnings.ContactLegal")}

</p>
</section>
            <Footer></Footer>
        </Fragment>
    )
}

export default Legal
