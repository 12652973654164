import React,{Fragment} from 'react'
import { animateScroll as scroll} from "react-scroll";
import { useTranslation } from "react-i18next";



const Cryptos = ({item,fiat}) => {

  //Translations

  const [t] = useTranslation("global");

  //Scroll Down Effect

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  
  //Date
  const d = new Date(item.last_updated)

    return(
        <Fragment>
        <section className="Section-coins-crypto">

            <article className="Article-container-cryptos">
                <div className="Head-crypto-container"><img className="Crypto-images" src={item.image}/>
                <div className="Info-name-lastupdate"><h3 className="Symbol-mobile">{item.symbol}</h3><h2 className="Item-name">{item.name}</h2><h6>{`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`}</h6></div>
                <div className="container-info-cryptos"></div><button   onClick={toggleHome} className="Buy-crypto-buttons">Buy</button></div>
                
                <h1 className="Crypto-Price" >{fiat}{item.current_price}</h1> 
                <h5 className="High-price">High: {item.high_24h}</h5>

                {item.price_change_24h < 0 ? (
            <p className='coin-percent coinpercenttop  red'>{item.price_change_24h.toFixed(2)}%</p>
          ) : (
            <p className='coin-percent coinpercenttop green'>{item.price_change_24h.toFixed(2)}%</p>
          )}
                <h6 className="Volumen24h">24 volumen: {item.market_cap_change_percentage_24h}</h6>
            </article>

        </section>
        </Fragment>
    )
}

export default Cryptos 