/*... Imports ... */
import "./App.css";
import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import MainPage from "./views/MainPage";
import TransactionPage from "./views/TransactionPage";
import SignUp from "./components/SignUp";
import ProtectedRoute from "./ProtectedRoute";
import AppAdmin from "./AppNewAdmin";
import MoreCripto from "./components/MoreCripto";
import Legal from "./components/Legals/Legal.js"
import Privacy from "./components/Legals/Privacy"
import Cookies from "./components/Legals/Cookies"

require("dotenv").config();

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

function App() {
  const [calculatorData, setCalculatorData] = useState({ isSubmitted: false });
  
  return (
    <Provider template={AlertTemplate} {...options}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <MainPage setCalculatorData={setCalculatorData} />}
        /> 
        <Route
          exact
          path="/transaction"
          render={() =>
            calculatorData.isSubmitted ? (
              <TransactionPage
                calculatorData={{ ...calculatorData }}
                setCalculatorData={setCalculatorData}
              />
            ) : (
              <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path="/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"
          component={SignUp}
        ></Route>
        <ProtectedRoute
          exact
          path="/ghp_iPBAa!YkJI0LtZif0r0PeH3K3WcQp3hIxz2mI02b"
          component={AppAdmin}
        />
        <Route exact path="/TopCrypto" component={MoreCripto} />
        <Route exact path="/Legalwarning" component={Legal}/>
        <Route exact path="/Privacy" component={Privacy}/>
        <Route exact path="/Cookies" component={Cookies}/>


      </Switch>
    </Provider>
  );
}

export default App;
