import React, { useState, useEffect,Fragment } from 'react';
import Coins from "../CriptoCoins/Coins"
import axios from 'axios';


const Criptotable = () => {
     
  const [table, setTable] = useState(false)
    const [items, setItems] = useState([])
    
    const [itemstwo, setItemsTwo] = useState([])

    useEffect(() => {
      const fetchItems = async () => {
        const result = await axios('https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&ids=bitcoin,tether,ethereum,ripple,cardano')
        console.log(result.data[1].symbol)
        setItems(result.data )
      }
      fetchItems()
    },[])

    useEffect(() => {
      const fetchItems = async () => {
        const result = await axios('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,tether,ethereum,ripple,cardano')
        console.log(result.data[1].symbol)
        setItemsTwo(result.data )
      }
      fetchItems()
    },[])
  
 return(
  <Fragment>

       <div className="Factsbtn-container">
      <div className="Facts-usd-btn" onClick={() => setTable(true)} >EUR</div>
      <div className="Facts-eur-btn" onClick={() => setTable(false) }>USD</div>
      </div> 

     {table ? <Coins fiat={"€"} items={items}> </Coins> : <Coins fiat={"$"} items={itemstwo}> </Coins> } 

  </Fragment>
 )
}

export default Criptotable;