import React, { useState } from "react";
import { useAlert } from 'react-alert';
import { useHistory } from "react-router-dom";
import favicon from "../Assetspatron/favicon.ico";
import { register, checkIfStatus400, checkIfStatus401, checkIfStatus403 } from "../api/requests";


function NewUserForm({ getUsersData, setModalTwoOpen }) {
    const [userReg, setUserReg] = useState({ user: "", name: "", pass1: "", pass2: "", role: "" })
    const alert = useAlert();
    const history = useHistory();

    const handleChange = (e) => {
        setUserReg(userReg => ({ ...userReg, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userReg.pass1 === userReg.pass2) {
            const data = {
                name: userReg.name,
                user: userReg.user,
                pass: userReg.pass1,
                role: userReg.role
            }

            const res = await register(data);
            if (res.success) {
                if (res.payload.success) {
                    alert.show(res.payload.msg, { type: 'success' })
                    getUsersData();
                    setModalTwoOpen(false)
                    setUserReg({ user: "", name: "", pass1: "", pass2: "", role: "" })
                } else {
                    alert.show(res.data.msg, { type: 'error' });
                    setModalTwoOpen(false)
                    setUserReg({ user: "", name: "", pass1: "", pass2: "", role: "" })
                }
            } else {
                checkIfStatus400(res, alert);
                checkIfStatus401(res, history);
                checkIfStatus403(res, alert);
            }
        } else {
            alert.show("Las contraseñas no coinciden", { type: 'error' })
        }
    }

    return (
        <section className="Section-modal-register">
            <div className="Register-card-container">
                <div className="Form-register-container">
                    <button className="Close-register" onClick={() => {
                        setModalTwoOpen(false)
                        setUserReg({ user: "", name: "", pass1: "", pass2: "", role: "" })
                    }
                    }>X</button>

                    <img
                        className="Register-ico"
                        src={favicon}
                        alt="Logo Patron Bit, is a dog with a bitcoint neckless"
                        style={{ width: "100px" }}
                    ></img>
                    <form
                        name="contact-form"
                        className="principalform  Register-form"
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact-form" />
                        <div className="input-container">
                            <input
                                placeholder="Usuario"
                                type="text"
                                name="user"
                                className="input"
                                value={userReg.user}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <input
                                placeholder="Nombre"
                                type="text"
                                name="name"
                                className="input"
                                value={userReg.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <input
                                placeholder="Contraseña"
                                type="password"
                                name="pass1"
                                className="input"
                                value={userReg.pass1}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="input-container">
                            <input
                                placeholder="Confirmar Contraseña"
                                type="password"
                                name="pass2"
                                className="input"
                                value={userReg.pass2}
                                onChange={handleChange}
                                required
                            />


                        </div>
                        <label className="Label-register" htmlFor="rol">Rol</label>

                        <select name="role" className="Select-rol-register" id="role" value={userReg.role} onChange={handleChange} required>
                            <option value="" disabled>Seleccionar</option>
                            <option value="super">Super</option>
                            <option value="admin">Admin</option>
                        </select>
                        <input type="submit" value="Registrar" className="btn" />
                    </form>
                </div>
            </div>
        </section>
    )
}

export default NewUserForm;