import {Fragment, useState} from "react";
import NavBar from "../components/NavBar"
import RedComponent from '../components/RedComponent';
import Landing from "../components/Landing"
import SecondSection from "../components/SecondSection"
import Facts from "../components/Facts"
import OurClients from "../components/OurClients"
import Adviser from "../components/Adviser"
import Spinner from "../components/Spinner"
import Footer from "../components/Footer"
import Testimonials from "../components/Testimonials";




function MainPage(props) {

    const [usd, setUsd] = useState(false);

    return (
        <Fragment>
             <NavBar />
    
            <Landing setCalculatorData={props.setCalculatorData}/>
            <RedComponent />
            <Spinner/>
            <Facts />
            <OurClients/>
            <SecondSection />
            <Adviser/>
            <Testimonials/>
            <Footer />
        </Fragment> 
    )
}

export default MainPage;