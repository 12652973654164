import React,{Fragment} from "react"
import Cryptos from "./Cryptos"
import right from "../Assetspatron/right.svg"
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Coins = ({items,fiat}) => {

    const [t] = useTranslation("global");
  
    return(
        <Fragment>
        
      
        <section className="Coins-section">
        {items.map(item => (
             <Cryptos key={item.name} item={item} fiat={fiat}></Cryptos>
         ))}
        </section>
        
        <section className="Container-morecryptos">
       
            <NavLink to="/TopCrypto" className="More-crypto">{t("Converter.Cryptos")}</NavLink>
            <NavLink to="/TopCrypto"> <img className="Right-arrow-crypto" src={right}></img></NavLink>
        </section>

       </Fragment>
    )
}


export default Coins