import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function RedComponent() {

    //Translations
    const [t] = useTranslation("global");

  return (
    <Fragment>
      <section className="Red-component-container"> 
      <h2 className="Red-component-title">{t("SecondSection.Redtitle")}</h2>
        <div className="Redcomponent-titles-container">
        <p className="Red-component-ptag">{t("SecondSection.Redtext")}</p>
        </div>
        <div>
        <p className="Red-component-ptag">{t("SecondSection.Redtexttwo")}</p>


        </div>

      </section>
    </Fragment>
  );
}
