import axios from "axios";
import * as Cons from "./constants";

export async function getData() {
  var config = {
    method: "get",
    url: Cons.GetData,
    headers: {},
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: false, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function getPrice(params) {
  var config = {
    method: "get",
    url: Cons.Price,
    headers: {},
    params,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { data: res.data, success: true }
  } catch (e) {
    console.error(e)   
    return { success: false } 
  }
}

export function checkIfStatus400(res, alert) {
  if(res.payload.status == 400) {
    return alert.show(res.payload.data.msg, {type: "error"})
  }
}

export function checkIfStatus401(res, history) {
  if(res.payload.status == 401) {
    if(res.payload.data.token) {
      logout()
      return history.push({
          pathname: '/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',
          state: { expired: true }
      }); 
    } else {
      return history.push("/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")
    }
  }
}

export function checkIfStatus403(res, alert) {
  if(res.payload.status == 403) {
    return alert.show(res.payload.data.msg, {type: "error"})
  }
}

export async function login(user, pass) {
  var config = {
    method: "post",
    url: Cons.Login,
    headers: {},
    data: {
      user,
      pass
    },
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data.msg, success: res.data.success }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response.msg }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function getTransactions() {
  var config = {
    method: "get",
    url: Cons.Transactions,
    headers: {},
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function getPercents() {
  var config = {
    method: "get",
    url: Cons.Gainpct,
    headers: {},
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function getUsers() {
  var config = {
    method: "get",
    url: Cons.Users,
    headers: {},
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function createTransaction(data) {
  var config = {
    method: "post",
    url: Cons.Transaction,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function editTransaction(data) {
  var config = {
    method: "put",
    url: Cons.Transaction,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function editPercent(data) {
  var config = {
    method: "put",
    url: Cons.Gainpct,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function deleteTransaction(data) {
  var config = {
    method: "delete",
    url: Cons.Transaction,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function editUser(data) {
  var config = {
    method: "put",
    url: Cons.User,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function deleteUser(data) {
  var config = {
    method: "delete",
    url: Cons.User,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function register(data) {
  var config = {
    method: "post",
    url: Cons.Register,
    headers: {},
    data,
    withCredentials: true
  };

  try {
    const res = await axios(config)
    return { payload: res.data, success: true }
  } catch (e) {
    console.error(e)
    if(e.response) {
      return { success: e.response.data.success, payload: e.response }
    } else {
      return { success: false, payload: null }
    }
    
  }
}

export async function logout(history) {
  var config = {
    method: "post",
    url: Cons.Logout,
    headers: {},
    withCredentials: true
  };

  try {
    const res = await axios(config)
    if (res.data.success) return history.push("/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");
  } catch (e) {
    console.error(e)    
  }
}