import React,{ useState, useEffect, Fragment} from 'react'


import NavBar from "./NavBarCri"
import App from "../components/MoreCriptos/App"
import Footer from "../components/FooterCri"



export default function MoreCripto() {
    return (
        <Fragment>
            <NavBar></NavBar>
            <App></App>
            <Footer></Footer>
        </Fragment>
    )
}
