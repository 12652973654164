const { REACT_APP_BACKEND_URL } = process.env;

export const url = REACT_APP_BACKEND_URL;

// Rutas
export const Logout = `${url}/logout`;
export const Login = `${url}/login`;
export const Price = `${url}/price`;
export const SendComment = `${url}/sendComment`;
export const Register = `${url}/register`;
export const GetData = `${url}/getData`;
export const Transactions = `${url}/transactions`;
export const Transaction = `${url}/transaction`;
export const Gainpct = `${url}/gainpct`;
export const Users = `${url}/users`;
export const User = `${url}/user`;