import React from "react";
import { Fragment, useEffect, useState,createContext } from "react";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import Calculator from "./Calculator";
import "aos/dist/aos.css";


export default function Landing(props) {
  //Translations
  const [t] = useTranslation("global");

  //Aos
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  //UseState Eur to Usd

    const [usd, setUsd] = useState(false);

    
  return (
    <Fragment>
      <section  className="Landing-home-container">
    
        <div className="Landing-container-home">
          <div data-aos="fade-up" className="Container-title-landing">
            <h1 className="Title-landing-one">{t("Home.Buysell")}</h1>
            <h1 className="Title-landing-two">{t("Home.Title")}</h1>
            <h1 className="Title-landing-three">
              {" "}
              <span className="title-span-landing">{t("Home.EASY")}</span>{" "}
              
              {t("Home.AND")}{" "}
              <span className="title-span-landing">{t("Home.SAFELY")}</span>
            </h1>
            <p className="Landing-text-home">{t("Home.Text-home")}</p>
          </div>
          <div>
     
        </div>
          <div className="Container-calculator">
          
              <Calculator setCalculatorData={props.setCalculatorData} />
        
          </div>
        </div>
 
      </section>
    </Fragment>
  );
}
