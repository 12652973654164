import React from "react"
import {Fragment,useEffect} from "react"
import Frame from "../Assetspatron/Frame.svg"
import Aos  from "aos"
import { useTranslation } from "react-i18next";
import {animateScroll as scroll} from "react-scroll"

import "aos/dist/aos.css"


export default function SecondSection(){

    //Translations
    const [t] = useTranslation("global");

    //Aos
    useEffect(() => {
        Aos.init({ duration:2000 });
    },[]);
    
    //Scroll
     
  const toggleHome = () => {
    scroll.scrollToTop()
  }
    return(
            <Fragment>
                <section id="SecondSection" className="SecondSection-container">
                    
                    <div className="Secondsection-content-container">
                       

                        <div className="Info-secondsection-container">
                            
                            <div className="Text-item-container">
                                
                            <h1 className="Secondsection-title">{t("SecondSection.Title")}</h1>
                              <h1 className="Secondsection-titletwo">{t("SecondSection.Titletwo")}</h1>
                                <p className="Seconsection-text-ptag">{t("SecondSection.textone")}</p>
                                <p className="Seconsection-text-ptag">{t("SecondSection.texttwo")}</p>
                                
                                <div className="Payment-methods">
                                <button className="Payment-btntwo transferbtn">{t("Converter.Transfer")}</button>
                                <button className="Payment-btntwo">{t("Converter.Card")}</button>
                                <button className="Payment-btntwo">{t("Converter.Cash")}</button>
                                </div>


                                <div className="Seconsection-btn-item">
                                <button onClick={toggleHome}  style={{cursor:"pointer"}} className="Secondsection-btn btnbuy">{t("Header.Buy")}</button>
                                <button onClick={toggleHome}   style={{cursor:"pointer"}}className="Secondsection-btn btnsell">{t("Header.Sell")}</button>
                                </div>
                            </div>
                            <div className="Image-secondsection-container">
                            <img data-aos="fade-up" src={Frame} alt="Logo"></img>
                        </div>
                        </div>
                     
                    </div>
                </section>
            </Fragment>
    );
}