import React,{Fragment} from 'react'
import { useTranslation } from "react-i18next";


//People
import Marta from "../Assetspatron/Marta.png"
import Rene from "../Assetspatron/Rene.png"
import Laura from "../Assetspatron/Laura.png"
import Amanda from "../Assetspatron/Amanda.png"
import Samanta from "../Assetspatron/Samanta.png"
import Carlos from "../Assetspatron/2.png"
import Jose from "../Assetspatron/3.png"
import Abdul from "../Assetspatron/4.png"
import Carousel from 'react-elastic-carousel'
import Star from "../Assetspatron/star.svg"







export default function Testimonials() {

      //Translations
  const [t] = useTranslation("global");

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
  ];
    return (
        <Fragment>

             <section id="Clients" className="Section-testimonials-container">
      
            <h1 className="Testimonials-title">{t("Clients.Title")}</h1>
                
                    <div className="Carousel-test">
                    <Carousel 
                    breakPoints={{ width: 850, itemsToShow: 1, itemsToScroll: 1 }}
                    showArrows = {true}
                    enableAutoPlay = {false}
                    autoPlaySpeed = {2000}
                    pagination = {false}
                    transitionMs = {1000}
                    itemsToShow={4}
                    breakPoints={breakPoints}>
                <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Marta}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Marta</h3>
                    <p>{t("Clients.Cardone")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    <div style={{display:"flex"}}>

                       

                    </div>
                    </div>
                </div>

                <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Amanda}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Amanda</h3>
                    <p>{t("Clients.Cardtwo")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    </div>
                </div>

                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Rene}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Carlos</h3>
                    <p>{t("Clients.Cardthree")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    </div>
                </div>

                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Abdul}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Ricardo</h3>
                    <p>{t("Clients.CardFour")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    </div>
                </div>

                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Jose}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Daniel</h3>
                    <p>{t("Clients.CardFive")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    </div>
                </div>

                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Laura}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Laura</h3>
                    <p>{t("Clients.CardSix")}</p>
                    <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                    </div>
                </div>

                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Carlos}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Angel</h3>
                    <p>{t("Clients.CardSeven")}</p>
                    <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                    </div>
                </div>



                     <div className="Testimonials-card-container">
                    <div className="Border-Testimonial">
                    <img className="Testimonials-img-container" alt="Testimonial" src={Samanta}/>
                    </div>
                    <div className="Info-testimonials">
                    <h3>Samanta</h3>
                    <p>{t("Clients.CardEight")}</p>
                         <div className="Start-container">
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}}src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        <img style={{width:"15px"}} src={Star}/>
                        </div>
                        
                    </div>
                </div>
                </Carousel>
                </div>
            </section>
            
            
 

        </Fragment>
    )
}
