import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { getData, checkIfStatus401 } from "./api/requests";

function ProtectedRoute({ Component: Component, ...restOfProps }) {
    const [ userData, setUserData ] = useState({success: false});
    const history = useHistory();

    useEffect(() => {
        async function getUserData() {
            let res = await getData();
            if(res.success) {
                setUserData({...res.payload})
            } else {
                if(res.payload) {
                    checkIfStatus401(res, history);
                } else {
                    console.log("error de red");
                }
            }
        }
        getUserData()

    }, [])

    return (
        <Route
        {...restOfProps}
        render={(props) =>
            userData.success ? <Component {...props} {...userData} /> : ""
        }
        />
    );
}

export default ProtectedRoute;