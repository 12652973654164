import React from "react";
import blacklogo from "../Assetspatron/logoblack.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { animateScroll as scroll, Link } from "react-scroll";


export default function FooterCri() {
  //Translations
  const [t] = useTranslation("global");

  //Scroll Down Effect

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <footer className="footer--section">
      <div className="footer__container ">
        <div className="footer__content">
          <NavLink to="/">
            <div className="Logo-container">
            <img
                onClick={toggleHome}
                src={blacklogo}
                alt="Logo Patron Bit, is a dog with a bitcoint neckless"
            ></img>
            </div>
        </NavLink>
        </div>
        <div className="footer__content">
        <ul>
            <li className="Footer-items">{t("Footer.Legal")}</li>
            <li className="Footer-items">{t("Footer.Privacy")}</li>
            <li className="Footer-items">{t("Footer.Cookie")}</li>
            <NavLink style={{textDecoration:"none"}}to="/TopCrypto" className="Footer-items">{t("Footer.Cryptocurrency")}</NavLink>
        </ul>
        </div>
        <div className="footer__content">
        <ul>
           <NavLink
              style={{ cursor: "pointer" }}
              to="/"
             
           
           ><li style={{cursor:"pointer"}}className="Footer-items">{t("Header.How")}</li></NavLink> 
            <NavLink
            to="/"
            style={{ cursor: "pointer" }}
            
            
            ><li className="Footer-items">{t("Header.About")}</li></NavLink>
           <NavLink
              style={{ cursor: "pointer" }}
              to="/"
              > <li  className="Footer-items">{t("Header.Contact")}</li></NavLink>
            <Link ><li style={{cursor:"pointer"}} onClick={toggleHome} className="Footer-items">{t("Header.Home")}</li></Link>
        </ul>
        </div>
        <div className="footer__content info-footer">
        <ul>
            <li className="Footer-items">PatronBit</li>
            <li className="Footer-items">Email:info@patronbit.es</li>
            <li className="Footer-items">
            {t("Footer.Hours")} 09:00H a 18:00H
            </li>
        </ul>
        </div>
    </div>

      <p className="footer__copy">
        &#169;2021 Patron Bit Todos los derechos reservados{" "}
      </p>
    </footer>
  );
}
