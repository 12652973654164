import React from "react";
import blacklogo from "../Assetspatron/logoblack.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { animateScroll as scroll, Link } from "react-scroll";
import Legal from "./Legals/Legal.js"

export default function Footer() {
  //Translations
  const [t] = useTranslation("global");

  //Scroll Down Effect

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <footer className="footer--section">
      <div className="footer__container ">
        <div className="footer__content">
          <NavLink to="/">
            <div className="Logo-container">
            <img
                onClick={toggleHome}
                src={blacklogo}
                alt="Logo Patron Bit, is a dog with a bitcoint neckless"
            ></img>
            </div>
        </NavLink>
        </div>
        <div className="footer__content">
        <ul>
            <NavLink to="/Legalwarning" className="Footer-items">{t("Footer.Legal")}</NavLink>
            <NavLink to="/Privacy" className="Footer-items">{t("Footer.Privacy")}</NavLink>
            <NavLink to="/Cookies" className="Footer-items">{t("Footer.Cookie")}</NavLink>
            <NavLink style={{textDecoration:"none"}}to="/TopCrypto" className="Footer-items">{t("Footer.Cryptocurrency")}</NavLink>
        </ul>
        </div>
        <div className="footer__content last-content">
        <ul className="last-content">
           <Link className="Footer-help"
              style={{ cursor: "pointer" }}
              to="Facts"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"
           
           ><li style={{cursor:"pointer"}}className="Footer-items">{t("Header.How")}</li></Link> 
            <Link 
            className="Footer-help"
            to="Clients"
            style={{ cursor: "pointer" }}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            activeClass="active"
            
            ><li className="Footer-items">{t("Header.About")}</li></Link>
           <Link
           className="Footer-help"
              style={{ cursor: "pointer" }}
              to="Adviser"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              activeClass="active"> <li  className="Footer-items">{t("Header.Contact")}</li></Link>
            <Link
            className="Footer-help"
            ><li style={{cursor:"pointer"}} onClick={toggleHome} className="Footer-items">{t("Header.Home")}</li></Link>
        </ul>
        </div>
        <div className="footer__content info-footer">
        <ul className="Footer-last-info">
            <li className="Footer-items">Email: info@patronbit.es</li>
            <li className="Footer-items Hours">
            {t("Footer.Hours")} 09:00H a 18:00H
            </li>
            <li className="Footer-items Hours" style={{textAlign:"justify"}}>
            Taleva NSP, S.L.  CIF: B67580993, {t("Footer.Direction")}: Consejo de ciento 492, local, 08013, Barcelona.
            </li>
        </ul>
        </div>
    </div>

      <p className="footer__copy">
        &#169;{t("Contact.Rights")}{" "}
      </p>
    </footer>
  );
}
