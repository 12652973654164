import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Ring } from "react-css-spinners";
import 'react-phone-number-input/style.css';
import { useAlert } from 'react-alert';

import PhoneInput from 'react-phone-number-input'
function Client(props) {

  const { REACT_APP_BACKEND_URL } = process.env;

  const [phone, setPhone] = useState(undefined);
  const { calculatorData } = props;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    formBtn: true,
  });
  const [response, setResponse] = useState({
    completed: false,
    isLoading: false,
  });
  const alert = useAlert();

  useEffect(() => {
    if (
      formData.name !== "" &&
      phone &&
      formData.email !== ""
    ) {
      if (formData.formBtn) {
        setFormData({ ...formData, formBtn: false });
      }
    } else {
      if (!formData.formBtn) {
        setFormData({ ...formData, formBtn: true });
      }
    }
  }, [formData, phone]);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  function handleSubmit(e) {
    setResponse({ ...response, isLoading: true });
    e.preventDefault();
    var config = {
      method: "post",
      url: `${REACT_APP_BACKEND_URL}/transaction`,
      headers: {},
      data: {
        name: formData.name,
        email: formData.email,
        phone: phone,
        operationType: calculatorData.operationType,
        amount: calculatorData.amount,
        fiatType: calculatorData.fiatType,
        cryptoQTY: calculatorData.cryptoQTY,
        cryptoType: calculatorData.cryptoType,
        paymentType: calculatorData.paymentType,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //setTimeout(() => setResponse({...response.data, completed: true, isLoading: false}), 3000)
        setResponse({ ...response.data, completed: true, isLoading: false });
      })

      .catch(function (error) {
        //console.log(error);
        if (error.response.status == 400) {
          alert.show(error.response.data.msg, { type: 'error' })
        }
        setResponse({ ...response.data, isLoading: false });
      });
  }
  //Translations
  const [t] = useTranslation("global");
  return (
    <Fragment>

      {/*console.log(props.calculatorData)*/}

      <section className="Client-section-container">
        <article className="First-step-container">
          <h2>{t("Client.title")}</h2>
          <div className="Information-client">
            <p className="Text-client-side">
              {t("Client.text")}{" "}
              {calculatorData.operationType === "v"
                ? t("Header.Sellclient")
                : calculatorData.operationType === "c"
                ? t("Header.Buyclient")
                : ""}{" "}
              {calculatorData.cryptoQTY}
              {calculatorData.cryptoType} {t("Client.text2")}{" "}
              {calculatorData.fiatType === "EUR" ? `${calculatorData.amount}€ ` : `${calculatorData.amount}$ `}{t("Client.text3")}{" "}
              {calculatorData.paymentType}?, {t("Client.text4")}
            </p>
          </div>
         
          <div className="Container-Forms">
            <form className={"Form"} id="Client-form" onSubmit={handleSubmit}>
              <div className="All-client-formcontainer">
                <div className="First-form-container">
                  <input
                    className="Client-input"
                    placeholder={t("Contact.Name")}
                    type="text"
                    id="name"
                    onChange={handleFormChange}
                    value={formData.name}
                    required
                  ></input>
                  <input
                    className="Client-input"
                    placeholder={t("Contact.Email")}
                    type="email"
                    id="email"
                    onChange={handleFormChange}
                    value={formData.email}
                    required
                  ></input>
                </div>
                <div className="Second-form-container">
                  <PhoneInput
                    onChange={setPhone}
                    className="Client-input"
                    placeholder={t("Contact.Phone")}
                    id="phone"
                    value={phone}
                    required
                  />
                  <div className="Amount-container">
                    <label>{t("Contact.Amount")}</label>
                    <div className="Amount-crypto">
                      {calculatorData.cryptoQTY}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <button
                disabled={formData.formBtn || response.completed}
                type="submit"
                id="Button-client"
                className={clsx(
                  "Ticket-generation-btn",
                  (formData.formBtn || response.completed) &&
                    "Calculator-btnbuy-disabled"
                )}
              >
                {t("Contact.Continue")}
              </button>
            </form>
          </div>
        </article>
      </section>
      {response.isLoading ? (
        <section className={"Spinner-ring"}>
          <Ring size={120} color={"#a82321"} />
        </section>
      ) : response.completed ? (
        <Fragment>
          <section className="Clientsecond-section-container">
            <div className="Ticket-number-container">
              <div className="Greyline"></div>
              <h1 className="Ticket-title">{t("Client.ticketitle")}</h1>
              <div className="Ticket-number">
                <h3>{response.completed ? response.ticket : ""}</h3>
              </div>
            </div>
          </section>
          <div className="Contact-client-options">
            <p>{t("Client.nextstep")}</p>
            <div className="Contact-btn-client">
            
              <a target="Blank" href="https://msng.link/o/?PatronBitOK=tg">
                <button className="Btn-contact">Telegram</button>
              </a>
              <a target="Blank" href="wa.link/aamtyw">
                <button className="Btn-contact">WhatsApp</button>
              </a>
              <a target="Blank" href="https://www.tidio.com/talk/qmnxgfvr2i9i2smimhvm1whqtduaqyvd">
                <button className="Btn-contact">{t("Header.Chat")}</button>
              </a>

            </div>
          </div>
        </Fragment>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default Client;
